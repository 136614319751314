import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

function Meja(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_meja, setDataMeja] = useState([]);
    const [no_meja, setNoMeja] = useState("");
    const [kapasitas, setKapasitas] = useState("");
    const [booking, setBooking] = useState(false);
    const [label_booking, setLabelBooking] = useState("Tidak Dibooking");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/meja/tampil_meja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "ada"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMeja(data.data);
                }
                else {
                    setDataMeja([]);
                }
            })
            .catch(error => {
                setDataMeja([]);
            }
            );

        setInterval(() => {
            fetch(props.aplikasi + '/meja/tampil_meja.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            status: "ada"
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataMeja(data.data);
                    }
                    else {
                        setDataMeja([]);
                    }
                })
                .catch(error => {
                    setDataMeja([]);
                }
                );
        }, 10000)
    }, [props.aplikasi]);

    const switch_warna_booking = (param) => {
        switch (param) {
            case "1":
                return "red";
            case "0":
                return "#37ce9b";
            default:
                break;
        }
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_meja = () => {
        fetch(props.aplikasi + '/meja/tampil_meja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "ada"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMeja(data.data);
                }
                else {
                    setDataMeja([]);
                }
            })
            .catch(error => {
                setDataMeja([]);
            }
            );
    }

    const bersih_input_meja = () => {
        setNoMeja("");
        setKapasitas("");
        setBooking(false);
        setLabelBooking("Tidak Dibooking");
    }

    const simpan = () => {
        fetch(props.aplikasi + '/meja/ubah_booking.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        no_meja: no_meja,
                        booking: booking
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_meja();
                    tampil_meja();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                }
                else {
                    tampil_meja();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                tampil_meja();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_meja.map((data_meja) => {
                        return (
                            <Grid
                                item xs={6} sm={4} md={3} lg={2}
                                key={data_meja.no_meja}
                                onClick={() => {
                                    setNoMeja(data_meja.no_meja);
                                    setKapasitas(data_meja.kapasitas);
                                    if (data_meja.booking === "1") {
                                        setBooking(true);
                                        setLabelBooking("Dibooking");
                                    }
                                    else {
                                        setBooking(false);
                                        setLabelBooking("Tidak Dibooking");
                                    }
                                    setTampilDialog(true);
                                }}
                            >
                                <Card sx={{ backgroundColor: "#66615b", color: "white" }}>
                                    <List>
                                        <ListItem button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: switch_warna_booking(data_meja.booking),
                                                    }}
                                                >
                                                    {data_meja.no_meja}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Typography noWrap variant="subtitle1">{"Kapasitas : " + data_meja.kapasitas}</Typography>}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Status Booking</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        No. Meja
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        {no_meja}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        Kapasitas Meja
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        {kapasitas}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        Booking
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={5}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={booking}
                                                onChange={(event) => {
                                                    if (booking === false) {
                                                        setBooking(true);
                                                        setLabelBooking("Dibooking");
                                                    }
                                                    else {
                                                        setBooking(false);
                                                        setLabelBooking("Tidak Dibooking");
                                                    }
                                                }}
                                            />
                                        }

                                        label={
                                            <Typography variant="body2">
                                                {label_booking}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        Simpan
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialog(false)}
                    >
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default forwardRef(Meja);