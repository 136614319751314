import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

function Otorisasi(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_metode, setDataMetode] = useState([]);    
    const [id_permintaan, setIdPermintaan] = useState("");
    const [kasir, setKasir] = useState("");
    const [grand_total, setGrandTotal] = useState("0");
    const [waktu_transaksi, setWaktuTransaksi] = useState("");
    const [metode_sekarang, setMetodeSekarang] = useState("");
    const [metode_baru, setMetodeBaru] = useState("");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetode(data.data);
                }
                else {
                    setDataMetode([]);
                }
            })
            .catch(error => {
                setDataMetode([]);
            }
            );        
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_metode = () => {
        fetch(props.aplikasi + '/metode_bayar/tampil_permintaan_ubah.php',
            {
                method: 'POST',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataMetode(data.data);
                }
                else {
                    setDataMetode([]);
                }
            })
            .catch(error => {
                setDataMetode([]);
            }
            );
    }         

    const simpan = (otorisasi) => {
        setProses(true);
        fetch(props.aplikasi + '/metode_bayar/otorisasi_ubah.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_permintaan: id_permintaan,
                        otorisasi: otorisasi
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {                    
                    tampil_metode();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
                else {
                    tampil_metode();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_metode();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_metode.map((data_metode) => {
                        return (
                            <Grid
                                item xs={12} sm={6} md={4} lg={3}
                                key={data_metode.id_permintaan}
                                onClick={() => {
                                    setIdPermintaan(data_metode.id_permintaan);
                                    setKasir(data_metode.kasir);
                                    setGrandTotal(data_metode.grand_total);
                                    setWaktuTransaksi(data_metode.waktu_pembayaran);
                                    setMetodeSekarang(data_metode.nama_metode_sekarang);
                                    setMetodeBaru(data_metode.nama_metode_baru);
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_metode.kasir + " (Rp. " + data_metode.grand_total + ")"}</Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <div>
                                                            <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>{data_metode.nama_metode_sekarang}</Typography>
                                                            <SyncAltIcon style={{ verticalAlign: "middle" }} />
                                                            <Typography variant="subtitle2" noWrap style={{ display: "inline" }}>{data_metode.nama_metode_baru}</Typography>
                                                        </div>
                                                        <Typography variant="subtitle2" noWrap>{data_metode.waktu_pembayaran}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Otorisasi Permintaan Ubah Metode Bayar</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Nama Kasir
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {kasir}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Jumlah Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        Rp. {grand_total}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Waktu Transaksi
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {waktu_transaksi}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Metode Sekarang
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {metode_sekarang}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={5}>
                                    <Typography variant="body2">
                                        Metode Baru
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {metode_baru}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan("SETUJU")}
                    >
                        <Typography variant="body2" noWrap={true}>Terima</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DoDisturbIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#c18446" }}
                        loading={proses}
                        onClick={() => simpan("TOLAK")}
                    >
                        <Typography variant="body2" noWrap={true}>Tolak</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(true);
                        }}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Permintaan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton 
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)} 
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            simpan("HAPUS");
                            setTampilDialogHapus(false);
                            setTampilDialog(false);                            
                        }}
                    >Ya</LoadingButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default forwardRef(Otorisasi);