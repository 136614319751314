import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

function Stok_Habis(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [data_jenis, setDataJenis] = useState([]);
    const [jenis, setJenis] = useState("");
    const [data_produk, setDataProduk] = useState([]);
    const [id_produk, setIdProduk] = useState("");
    const [nama, setNama] = useState("");
    const [habis, setHabis] = useState(false);
    const [label_habis, setLabelHabis] = useState("Tersedia");

    //fungsi
    // useEffect(async () => {
    //     const proses_jenis = await tampil_jenis();
    //     const proses_produk = await tampil_produk(proses_jenis[0].id_jenis);
    //     setDataJenis(proses_jenis);
    //     setJenis(proses_jenis[0].id_jenis);
    //     setDataProduk(proses_produk);

    //     // setInterval(async () => {
    //     //     const proses_produk = await tampil_produk(jenis);
    //     //     setDataProduk(proses_produk);
    //     // }, 10000)
    // }, [props.aplikasi]);

    useEffect(() => {
        const efek_tampil_jenis = () => {
            return new Promise((resolve) => {
                fetch(props.aplikasi + '/jenis_produk/tampil_jenis_produk.php',
                    {
                        method: 'POST',
                        body: JSON.stringify
                            ({
                                status: "1"
                            }),
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            resolve(data.data);
                        }
                        else {
                            resolve([]);
                        }
                    })
                    .catch(error => {
                        resolve([]);
                    }
                    );
            });
        }

        const efek_tampil_produk = (id_jenis) => {
            return new Promise((resolve) => {
                fetch(props.aplikasi + '/produk/tampil_produk.php',
                    {
                        method: 'POST',
                        body: JSON.stringify
                            ({
                                status: "1"
                            }),
                        headers:
                        {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.kode === 1) {
                            if (id_jenis !== "" && id_jenis !== null) {
                                var awal = 1;
                                var akhir = data.data.length;

                                var tampung = [];
                                while (awal <= akhir) {
                                    if (data.data[awal - 1]["id_jenis"] === id_jenis) {
                                        tampung.push(data.data[awal - 1]);
                                    }
                                    awal++;
                                }

                                resolve(tampung);
                                return;
                            }
                            resolve(data.data);
                        }
                        else {
                            resolve([]);
                        }
                    })
                    .catch(error => {
                        resolve([]);
                    }
                    );
            });
        }

        const tampil_awal = async () => {
            const proses_jenis = await efek_tampil_jenis();
            const proses_produk = await efek_tampil_produk(proses_jenis[0].id_jenis);
            setDataJenis(proses_jenis);
            setJenis(proses_jenis[0].id_jenis);
            setDataProduk(proses_produk);
        }

        tampil_awal();
    }, [props.aplikasi])


    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const ubah_jenis_produk = async (id_jenis) => {
        setJenis(id_jenis);
        const proses_produk = await tampil_produk(id_jenis);
        setDataProduk(proses_produk);
    }

    // const tampil_jenis = () => {
    //     return new Promise((resolve) => {
    //         fetch(props.aplikasi + '/jenis_produk/tampil_jenis_produk.php',
    //             {
    //                 method: 'POST',
    //                 body: JSON.stringify
    //                     ({
    //                         status: "1"
    //                     }),
    //                 headers:
    //                 {
    //                     "Content-type": "application/json; charset=UTF-8"
    //                 }
    //             })
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data.kode === 1) {
    //                     resolve(data.data);
    //                 }
    //                 else {
    //                     resolve([]);
    //                 }
    //             })
    //             .catch(error => {
    //                 resolve([]);
    //             }
    //             );
    //     });
    // }

    const tampil_produk = (id_jenis) => {
        return new Promise((resolve) => {
            fetch(props.aplikasi + '/produk/tampil_produk.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            status: "1"
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        if (id_jenis !== "" && id_jenis !== null) {
                            var awal = 1;
                            var akhir = data.data.length;

                            var tampung = [];
                            while (awal <= akhir) {
                                if (data.data[awal - 1]["id_jenis"] === id_jenis) {
                                    tampung.push(data.data[awal - 1]);
                                }
                                awal++;
                            }

                            resolve(tampung);
                            return;
                        }
                        resolve(data.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(error => {
                    resolve([]);
                }
                );
        });
    }

    const bersih_input_habis = () => {
        setIdProduk("");
        setNama("");
        setHabis(false);
        setLabelHabis("Tersedia");
    }

    const simpan = () => {
        fetch(props.aplikasi + '/produk/stok_habis.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_produk: id_produk,
                        habis: habis
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_habis();
                    ubah_jenis_produk(jenis);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                }
                else {
                    ubah_jenis_produk(jenis);
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                ubah_jenis_produk(jenis);
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        centered
                        value={jenis}
                        onChange={(event, value) => ubah_jenis_produk(value)}
                    >
                        {data_jenis.map((data_jenis) => {
                            return (
                                <Tab key={data_jenis.id_jenis} value={data_jenis.id_jenis} label={data_jenis.nama} />
                            )
                        })}
                    </Tabs>
                </Box>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_produk.map((data_produk) => {
                        return (
                            <Grid
                                key={data_produk.id_produk}
                                sx={{ margin: "10px", width: "150px" }}
                                onClick={() => {
                                    setIdProduk(data_produk.id_produk);
                                    setNama(data_produk.nama);
                                    if (data_produk.habis === "1") {
                                        setHabis(true);
                                    }
                                    else {
                                        setHabis(false);
                                    }
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <ImageListItem>
                                        <CardMedia
                                            component="img"
                                            src={props.file + "/Foto/Aplikasi/stok habis.png"}
                                            sx={data_produk.habis === "1"
                                                ?
                                                {
                                                    position: "absolute",
                                                    objectFit: "contain",
                                                    width: "150px",
                                                    height: "150px"
                                                }
                                                :
                                                {
                                                    display: "none"
                                                }
                                            }
                                        />
                                        <CardMedia
                                            component="img"
                                            src={props.file + "/Foto/Produk/" + data_produk.foto}
                                            sx={{
                                                backgroundColor: "gray",
                                                objectFit: "cover",
                                                width: "150px",
                                                height: "150px"
                                            }}
                                        />
                                        <ImageListItemBar
                                            title={data_produk.nama}
                                            subtitle={"Rp. " + data_produk.harga2}
                                        />
                                    </ImageListItem>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Stok Habis</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item>
                                <Grid item xs={4}>
                                    <Typography variant="body2">
                                        Nama Produk
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <Typography variant="body2">
                                        {nama}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={4}>
                                    <Typography variant="body2">
                                        Stok Habis
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography variant="body2">
                                        :
                                    </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={habis}
                                                onChange={(event) => {
                                                    if (habis === false) {
                                                        setHabis(true);
                                                        setLabelHabis("Habis");
                                                    }
                                                    else {
                                                        setHabis(false);
                                                        setLabelHabis("Tersedia");
                                                    }
                                                }}
                                            />
                                        }

                                        label={
                                            <Typography variant="body2">
                                                {label_habis}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        onClick={() => simpan()}
                    >
                        Simpan
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        onClick={() => setTampilDialog(false)}
                    >
                        Tutup
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default forwardRef(Stok_Habis);