import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

//form
import Login from './login';
import Utama from './utama';
import ProtectedRoute from "./ProtectedRoute";

// const url_aplikasi = "http://localhost/restoran_api";
// const url_file = "http://localhost/restoran_file";
// const basename = "";
// const basename = "/build";

// const url_aplikasi = "http://192.168.167.21/restoran_api";
// const url_file = "http://192.168.167.21/restoran_file";
// const basename = "";
// const basename = "/restoran";

// const url_aplikasi = "https://notebookpedia.com/restoran_api";
// const url_file = "https://notebookpedia.com/restoran_file";
// const basename = "/restoran";

const url_aplikasi = "https://puassemarang.com/rm/restoran_api";
const url_file = "https://puassemarang.com/rm/restoran_file";
const basename = "";

function App() {
  return (
    <BrowserRouter basename={basename}>
      <Switch>
        <Route
          path="/login"
          render={(props) => (
            <Login {...props}
              aplikasi={url_aplikasi}
              file={url_file}
            />
          )}
        />
        <ProtectedRoute path="/">
          <Utama
            aplikasi={url_aplikasi}
            file={url_file}
            basename={basename}
          />
        </ProtectedRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;