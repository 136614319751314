import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Input = styled('input')({
    display: 'none',
});

function Pengaturan(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [nama, setNama] = useState("");
    const [username, setUsername] = useState("");
    const [ubah_password, setUbahPassword] = useState(false);
    const [label_ubah_password, setLabelUbahPassword] = useState("Tidak");
    const [password_lama, setPasswordLama] = useState("");
    const [tampil_password_lama, setTampilPasswordLama] = useState(false);
    const [password_baru, setPasswordBaru] = useState("");
    const [tampil_password_baru, setTampilPasswordBaru] = useState(false);
    const [password_konfirmasi, setPasswordKonfirmasi] = useState("");
    const [tampil_password_konfirmasi, setTampilPasswordKonfirmasi] = useState(false);
    const [tampung_foto, setTampungFoto] = useState(null);
    const [foto, setFoto] = useState(null);
    const [jenis_file, setJenisFile] = useState("");

    //fungsi
    useEffect(() => {
        setNama(localStorage.getItem("nama"));
        setUsername(localStorage.getItem("username"));
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = () => {
        if (ubah_password === true) {
            if (password_lama === "") {
                setJenisNotif("warning");
                setIsiNotif("Password lama wajib diisk");
                setTampilNotif(true);
                document.getElementById("password_lama").focus();
                return;
            }

            if (password_baru === "") {
                setJenisNotif("warning");
                setIsiNotif("Password baru wajib diisk");
                setTampilNotif(true);
                document.getElementById("password_baru").focus();
                return;
            }

            if (password_konfirmasi === "") {
                setJenisNotif("warning");
                setIsiNotif("Konfirmasi password baru wajib diisk");
                setTampilNotif(true);
                document.getElementById("password_konfirmasi").focus();
                return;
            }

            if (password_baru !== password_konfirmasi) {
                setJenisNotif("warning");
                setIsiNotif("Password baru tidak sama dengan konfirmasi");
                setTampilNotif(true);
                document.getElementById("password_baru").focus();
                return;
            }
        }

        fetch(props.aplikasi + '/pesanan/simpan_pengaturan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        username: username,
                        nama: nama,
                        ubah_password: ubah_password,
                        password_lama: password_lama,
                        password_baru: password_baru,
                        password_konfirmasi: password_konfirmasi,
                        foto: foto,
                        jenis_file: jenis_file
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    localStorage.setItem("nama", nama);
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    if (foto !== null) {
                        localStorage.setItem("foto", localStorage.id_pengguna + "." + jenis_file);
                    }
                    document.getElementById("nama").focus();
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
            }
            );
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    padding: "10px"
                }}
            >
                <AppBar
                    sx={{
                        display: 'flex'
                    }}
                >
                    <Toolbar
                        sx={{ backgroundColor: "#fe9400" }}
                    >
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                        </Typography>

                        <Button autoFocus color="inherit"
                            onClick={() => simpan()}
                        >
                            Simpan
                        </Button>
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: "64px", padding: "10px" }}>
                    <center>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <label htmlFor="icon-button-file">
                                    <Input accept="image/*" id="icon-button-file" type="file"
                                        onChange={(e) => {
                                            setFoto(e.target.files[0]);
                                            setTampungFoto(e.target.files[0]);
                                            setJenisFile(e.target.files[0].name.split(".").pop());
                                            let reader = new FileReader();
                                            reader.readAsDataURL(e.target.files[0]);
                                            reader.onload = () => {
                                                setFoto(reader.result);
                                            }
                                        }}
                                    />
                                    <Fab
                                        size="small"
                                        color="primary"
                                        component="span"
                                    >
                                        <PhotoCamera />
                                    </Fab>
                                </label>
                            }
                        >
                            <Avatar
                                src={tampung_foto !== null ? URL.createObjectURL(tampung_foto) : props.file + "/Foto/Pengguna/" + localStorage.foto}
                                sx={{ width: 128, height: 128 }}
                            />
                        </Badge>
                    </center>
                    <br />
                    <Grid container item spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Username
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="username"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Nama Lengkap
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="nama"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={nama}
                                    onChange={(event) => setNama(event.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Ubah Password
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={ubah_password}
                                            onChange={(event) => {
                                                if (ubah_password === false) {
                                                    setUbahPassword(true);
                                                    setLabelUbahPassword("Ya");
                                                    setPasswordLama("");
                                                    setPasswordBaru("");
                                                    setPasswordKonfirmasi("");
                                                }
                                                else {
                                                    setUbahPassword(false);
                                                    setLabelUbahPassword("Tidak");
                                                }
                                            }}
                                        />
                                    }

                                    label={label_ubah_password}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={ubah_password === true ? {} : { display: "none" }}
                        >
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Password Lama
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="password_lama"
                                        size="small"
                                        fullWidth
                                        type={tampil_password_lama ? "text" : "password"}
                                        value={password_lama}
                                        onChange={(event) => setPasswordLama(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(event) => setTampilPasswordLama(!tampil_password_lama)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    edge="end"
                                                >
                                                    {tampil_password_lama ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={ubah_password === true ? {} : { display: "none" }}
                        >
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Password Baru
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="password_baru"
                                        size="small"
                                        fullWidth
                                        type={tampil_password_baru ? "text" : "password"}
                                        value={password_baru}
                                        onChange={(event) => setPasswordBaru(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(event) => setTampilPasswordBaru(!tampil_password_baru)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    edge="end"
                                                >
                                                    {tampil_password_baru ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item
                            sx={ubah_password === true ? {} : { display: "none" }}
                        >
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Konfirmasi Password Baru
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <OutlinedInput
                                        id="password_konfirmasi"
                                        size="small"
                                        fullWidth
                                        type={tampil_password_konfirmasi ? "text" : "password"}
                                        value={password_konfirmasi}
                                        onChange={(event) => setPasswordKonfirmasi(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={(event) => setTampilPasswordKonfirmasi(!tampil_password_konfirmasi)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    edge="end"
                                                >
                                                    {tampil_password_konfirmasi ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Fragment>
    );
}

export default forwardRef(Pengaturan);