import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function Beranda(props, ref) {
    //state    
    const [jumlah, setJumlah] = useState(0);
    const [data_terlaris_makanan_hari, setDataTerlarisMakananHari] = useState([]);
    const [data_terlaris_minuman_hari, setDataTerlarisMinumanHari] = useState([]);
    const [data_terlaris_makanan_bulan, setDataTerlarisMakananBulan] = useState([]);
    const [data_terlaris_minuman_bulan, setDataTerlarisMinumanBulan] = useState([]);
    const [data_habis, setDataHabis] = useState([]);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/pesanan/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setJumlah(data.data[0].jumlah);
                    setDataTerlarisMakananHari(data.data[0].terlaris_makanan_hari);
                    setDataTerlarisMinumanHari(data.data[0].terlaris_minuman_hari);
                    setDataTerlarisMakananBulan(data.data[0].terlaris_makanan_bulan);
                    setDataTerlarisMinumanBulan(data.data[0].terlaris_minuman_bulan);
                    setDataHabis(data.data[0].habis);
                }
                else {
                    setJumlah(0);
                    setDataTerlarisMakananHari([]);
                    setDataTerlarisMinumanHari([]);
                    setDataTerlarisMakananBulan([]);
                    setDataTerlarisMinumanBulan([]);
                    setDataHabis([]);
                }
            })
            .catch(error => {
                setJumlah(0);
                setDataTerlarisMakananHari([]);
                setDataTerlarisMinumanHari([]);
                setDataTerlarisMakananBulan([]);
                setDataTerlarisMinumanBulan([]);
                setDataHabis([]);
            }
            );

        setInterval(() => {
            fetch(props.aplikasi + '/pesanan/tampil_beranda.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setJumlah(data.data[0].jumlah);
                        setDataTerlarisMakananHari(data.data[0].terlaris_makanan_hari);
                        setDataTerlarisMinumanHari(data.data[0].terlaris_minuman_hari);
                        setDataTerlarisMakananBulan(data.data[0].terlaris_makanan_bulan);
                        setDataTerlarisMinumanBulan(data.data[0].terlaris_minuman_bulan);
                        setDataHabis(data.data[0].habis);
                    }
                    else {
                        setJumlah(0);
                        setDataTerlarisMakananHari([]);
                        setDataTerlarisMinumanHari([]);
                        setDataTerlarisMakananBulan([]);
                        setDataTerlarisMinumanBulan([]);
                        setDataHabis([]);
                    }
                })
                .catch(error => {
                    setJumlah(0);
                    setDataTerlarisMakananHari([]);
                    setDataTerlarisMinumanHari([]);
                    setDataTerlarisMakananBulan([]);
                    setDataTerlarisMinumanBulan([]);
                    setDataHabis([]);
                }
                );
        }, 10000)
    }, [props.aplikasi]);

    return (
        <Fragment>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    padding: "10px"
                }}
            >
                <Box sx={{ padding: "10px" }}>
                    <center>
                        <Avatar
                            src={props.file + "/Foto/Aplikasi/logo.png"}
                            sx={{ width: 128, height: 128 }}
                        />
                    </center>
                    <br />
                    <center>
                        <Typography variant="body2">
                            Jumlah Pelayanan Hari Ini
                        </Typography>
                        <Typography variant="h3" sx={{ color: "red" }}>
                            {jumlah}
                        </Typography>
                    </center>
                    <br />

                    <Grid container item spacing={1}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12} sm={6}>
                            <Card
                                    sx={{ padding: "10px", backgroundColor: "white" }}
                                >
                                    <Typography variant="h6">
                                        Produk Terlaris
                                    </Typography>
                                    <Divider />
                                    <br />

                                    <Grid
                                        container
                                    >
                                        <Grid
                                            item xs={5}
                                        >
                                            <Typography variant="h6">
                                                Hari Ini
                                            </Typography>
                                            <Grid container item spacing={1}>
                                                <Grid container item xs={12}>
                                                    <Typography variant="body1"
                                                        sx={{
                                                            padding: "5px",
                                                            backgroundColor: "#baa8e6",
                                                            color: "white"
                                                        }}
                                                    >
                                                        Makanan
                                                    </Typography>
                                                </Grid>
                                                {data_terlaris_makanan_hari.map((data_terlaris_makanan, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris_makanan.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {data_terlaris_makanan.jumlah}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}

                                                <Grid container item xs={12}>
                                                    <Typography variant="body1"
                                                        sx={{
                                                            padding: "5px",
                                                            backgroundColor: "#baa8e6",
                                                            color: "white"
                                                        }}
                                                    >
                                                        Minuman
                                                    </Typography>
                                                </Grid>
                                                {data_terlaris_minuman_hari.map((data_terlaris_minuman, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris_minuman.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {data_terlaris_minuman.jumlah}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            // container alignItems="center" justifyContent="flex-end"
                                            // item xs={2}
                                            container item xs={1} justifyContent="center"
                                        >
                                            <Divider orientation='vertical' variant='middle' />
                                        </Grid>

                                        <Grid
                                            item xs={6}
                                        >
                                            <Typography variant="h6">
                                                Bulan Ini
                                            </Typography>
                                            <Grid container item spacing={1}>
                                                <Grid container item xs={12}>
                                                    <Typography variant="body1"
                                                        sx={{
                                                            padding: "5px",
                                                            backgroundColor: "#baa8e6",
                                                            color: "white"
                                                        }}
                                                    >
                                                        Makanan
                                                    </Typography>
                                                </Grid>
                                                {data_terlaris_makanan_bulan.map((data_terlaris_makanan, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris_makanan.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {data_terlaris_makanan.jumlah}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}

                                                <Grid container item xs={12}>
                                                    <Typography variant="body1"
                                                        sx={{
                                                            padding: "5px",
                                                            backgroundColor: "#baa8e6",
                                                            color: "white"
                                                        }}
                                                    >
                                                        Minuman
                                                    </Typography>
                                                </Grid>
                                                {data_terlaris_minuman_bulan.map((data_terlaris_minuman, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris_minuman.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {data_terlaris_minuman.jumlah}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Card sx={{ padding: "10px" }}>
                                    <Typography variant="h6">
                                        Stok Habis
                                    </Typography>
                                    <Divider />
                                    <br />
                                    <Grid container item spacing={1}>
                                        {data_habis.map((data_habis, index) => {
                                            return (
                                                <Grid key={index} item xs={12}>
                                                    <Typography variant="body2">
                                                        {data_habis.nama}
                                                    </Typography>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Fragment>
    );
}

export default forwardRef(Beranda);